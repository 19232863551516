<template>
    <BModal
        id="loadDataMapModal"
        ref="loadDataMapModal"
        size="lg"
        modal-class="bar bar-primary"
        header-class="align-items-center"
        no-close-on-backdrop
        static
        visible
        @hide="close"
        @shown="$refs.objectInput.focus()"
    >
        <template slot="modal-header">
            <h4
                class="modal-title"
                data-cy="loadDataMapModalHeader"
            >
                Load a Data Map
            </h4>

            <FontAwesomeIcon
                icon="times"
                class="ml-auto modal-close"
                size="2x"
                @click="close"
            />
        </template>

        <div class="row">
            <div class="col-12">
                <BFormGroup>
                    <template slot="label">
                        Object
                    </template>

                    <BFormTextarea
                        ref="objectInput"
                        v-model="objectInput"
                        rows="10"
                        data-cy="objectInput"
                        placeholder="Paste an object to map..."
                    />
                </BFormGroup>
            </div>
        </div>

        <template slot="modal-footer">
            <button
                id="loadDataMapButton"
                type="button"
                class="btn btn-primary"
                data-cy="loadObjectButton"
                :disabled="saving || !objectInput"
                @click="save"
            >
                <template
                    v-if="saving"
                >
                    <FontAwesomeIcon
                        class="mr-1"
                        icon="spinner"
                        pulse
                    />
                    Loading Object to Map...
                </template>
                <template
                    v-else
                >
                    Load Object to Map
                    <FontAwesomeIcon
                        class="ml-1"
                        icon="angle-right"
                    />
                </template>
            </button>

            <button
                type="button"
                class="btn btn-secondary"
                @click="close"
            >
                Cancel
            </button>
        </template>
    </BModal>
</template>

<script>
    import apiMixin from '@imt/vue-toolbox/src/mixins/api';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';

    export default {
        name: 'MDataMapLoadModal',
        mixins: [
            apiMixin,
            toastsMixin,
        ],
        metaInfo: {
            title: 'Add Data Map',
        },
        data() {
            return {
                objectInput: '',
                saving: false,
                versionId: this.$route.params.versionId,
                dataMapId: this.$route.params.dataMapId
            };
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.objectInput = localStorage.getItem(`loadedObject${this.versionId}`);
            },
            async save() {
                this.saving = true;
                try {
                    localStorage.setItem(`loadedObject${this.versionId}`, this.objectInput.replace(/'/g, `"`));

                    this.success('Object loaded successfully.', 'Load Object');
                    await this.$router.push({
                        name: 'admin.datamaps.builder',
                        params: {dataMapId: this.dataMapId, versionId: this.versionId},
                    });
                } catch (e) {
                    this.saving = false;
                    this.error('Error loading object, please try again.', 'Load Object');
                }
            },
            async close() {
                await this.$router.push({name: 'admin.datamaps.builder'});
            },
        },
    };
</script>

<style
    lang="sass"
>
    body
        &,
        &.dark.mode
            .modal .form-group
                font-weight: normal
                margin-bottom: 1rem

                &,
                &.is-valid,
                &.is-invalid
                    label,
                    legend
                        width: 100%
                        font-weight: bold
</style>

<style
    lang="sass"
    scoped
>
    .modal-close
        opacity: 0.5
        transition: opacity 250ms

        &:hover
            opacity: 1.0
            cursor: pointer
</style>
